// FONTS


@font-face {
  font-family: 'euro';
  src: url('../fonts/euro/euro.eot');
  src: url('../fonts/euro/euro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/euro/euro.woff') format('woff'),
    url('../fonts/euro/euro.ttf') format('truetype'),
    url('../fonts/euro/euro.svg#euro') format('svg');
  font-weight: normal;
  font-style: normal;
}
